import React from "react";
import JobPositionsCards from "../components/JobPositionsCards/JobPositionsCards";

const Jobs = () => {
  return (
    <div className="pt-8">
      <JobPositionsCards />
    </div>
  );
};

export default Jobs;
