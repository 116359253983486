const HeaderData = [
  {
    id: 1,
    title: "About Us",
    link: "/#AboutUs",
  },
  {
    id: 2,
    title: "Our Services",
    link: "/ourServices",
  },

  {
    id: 3,
    title: "Career",
    link: "/career",
  },
  // {
  //   id: 4,
  //   title: "Contact Us",
  //   link: "/ContactUs",
  // },
];

export default HeaderData;
