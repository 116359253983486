import React from "react";

const Congratulations = () => {
  return (
    <div className="flex flex-col items-center bg-white py-10 rounded-2xl shadow-xl  w-11/12 m-auto mb-10">
      <div className="bg-green-100 p-4 rounded-full">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-20 w-20 text-green-600"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
      <h1 className="text-4xl font-bold text-gray-900 mt-5">Thank You!</h1>
      <p className="text-gray-600 text-center mt-3 px-6">
        We appreciate you reaching out. Your application has been received, and
        we will get back to you as soon as possible.
      </p>
      <p className="text-gray-600 text-center mt-2 px-6">
        In the meantime, feel free to explore more about our services.
      </p>
    </div>
  );
};

export default Congratulations;
