import React, { useState, useEffect } from "react";
import ApplyForm from "./ApplyForm";
import Congratulations from "./Congratulations";
import data from "./data";
import "./ApplyForm.scss";
import Modal from "../Modal/Modal";

const OpenPositionsApply = (props) => {
  const jobTitle = props.onGetData;
  const [applicationForm, setApplicationForm] = useState(false);
  const [appFinished, setAppFinished] = useState(false);

  useEffect(() => {
    if (appFinished) {
      setTimeout(() => {
        setAppFinished(false);
        props.onClose();
      }, 5000);
    }
  }, [appFinished, props]);

  return (
    <Modal variant={"white"} onClose={props.onClose} headline={jobTitle}>
      {appFinished ? (
        <Congratulations />
      ) : (
        <>
          {applicationForm ? (
            <div>
              <ApplyForm jobTitle={jobTitle} setAppFinished={setAppFinished} />
            </div>
          ) : (
            <div className="relative job-apply mx-5">
              {data.map((el) =>
                el.jobtitle === jobTitle ? (
                  <div className="w-full max-sm:mb-10" key={el.id}>
                      <h3 className="text-base sm:text-lg topline">
                        The job and technologies:
                      </h3>
                      <ul>
                        <li
                          className="copy-text list-disc"
                          dangerouslySetInnerHTML={{ __html: el.criteria }}
                        ></li>
                      </ul>
                      <h3 className="text-base sm:text-lg topline">
                        Your profile
                      </h3>
                      <ul>
                        <li
                          className="copy-text list-disc"
                          dangerouslySetInnerHTML={{
                            __html: el.qualifications,
                          }}
                        ></li>
                      </ul>
                      <h3 className="text-base sm:text-lg topline">
                        Your benefits
                      </h3>
                      <ul>
                        <li
                          className="copy-text list-disc"
                          dangerouslySetInnerHTML={{ __html: el.benefits }}
                        ></li>
                      </ul>

                      <button
                        onClick={() => setApplicationForm(true)}
                        className="my-5 bg-darkGreen rounded-3xl text-white font-bold active:bg-formButtonHover hover:bg-formButtonHover shadow-shadow text-center py-0.5 px-6 h-12 transition-background-color duration-300 ease-linear"
                      >
                        Apply now
                      </button>
                    </div>
                ) : null
              )}
            </div>
          )}
        </>
      )}
    </Modal>
  );
};

export default OpenPositionsApply;
