import React, { useState } from "react";
import { BaseUrl } from "../../helpers";

const ApplyForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    email: "",
    mobile: "",
    file: "",
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setFileName(files[0].name);
      setFormData((prevState) => ({
        ...prevState,
        [name]: files[0],
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataApp = new FormData();
    formDataApp.append("name", formData.name);
    formDataApp.append("surname", formData.surname);
    formDataApp.append("email", formData.email);
    formDataApp.append("mobile", formData.mobile);
    formDataApp.append("position", props.jobTitle);
    if (formData.file) {
      formDataApp.append("pdfFile", formData.file);
    }

    try {
      setLoading(true);
      const response = await fetch(`${BaseUrl}upload`, {
        method: "POST",
        body: formDataApp,
      });

      if (response.ok) {
        props.setAppFinished(true);
        const data = await response.json();
        console.log(data);
      } else {
        console.error("Error uploading file", response.statusText);
      }
    } catch (err) {
      console.error("Error", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className=" w-11/12 m-auto">
      <h2 className="hdl-4 sm:hdl-3 ">
        Send your <span className="text-darkGreen">job application</span> form
      </h2>
      <div className="md:flex justify-between  gap-10">
        {" "}
        <div className="relative mt-4 md:w-11/12">
          <input
            type="text"
            name="name"
            className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-white  border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-darkGreen peer"
            placeholder=" "
            required
            onChange={handleChange}
          />
          <label
            htmlFor="floating_filled"
            className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-darkGreen  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
          >
            Name
          </label>
        </div>
        <div className="relative mt-4 md:w-11/12">
          <input
            type="text"
            name="surname"
            className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-white  border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0 focus:border-darkGreen peer"
            placeholder=" "
            required
            onChange={handleChange}
          />
          <label
            htmlFor="floating_filled"
            className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-darkGreen  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
          >
            Surname
          </label>
        </div>
      </div>

      <div className="relative mt-4">
        <input
          type="email"
          name="email"
          className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-white  border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-darkGreen peer"
          placeholder=" "
          required
          onChange={handleChange}
        />
        <label
          htmlFor="floating_filled"
          className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-darkGreen  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
        >
          Email
        </label>
      </div>

      <div className="relative mt-4">
        <input
          type="number"
          name="mobile"
          className="block rounded-t-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-gray-900 bg-white  border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-darkGreen peer"
          placeholder=" "
          required
          onChange={handleChange}
        />
        <label
          htmlFor="floating_filled"
          className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-darkGreen peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
        >
          Mobile
        </label>
      </div>

      <div className="flex items-center justify-center w-full mt-3">
        <label className="flex flex-col items-center justify-center w-full h-45 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50   hover:bg-gray-100   ">
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <svg
              className="w-8 h-8 mb-4 text-gray-500 "
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 16"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
              />
            </svg>
            <p className="mb-2 text-sm text-gray-500 ">
              <span className="font-semibold">Click to upload your Resume</span>{" "}
            </p>
            <p className="text-xs text-gray-500 ">PDF Format</p>
          </div>
          <input
            id="dropzone-file"
            type="file"
            name="file"
            className="hidden"
            onChange={handleChange}
          />
        </label>
      </div>
      {fileName && (
        <p className="mt-2 text-sm text-darkGreen font-medium">{fileName}</p>
      )}
      <div className="w-full text-center m-auto">
        <button
          disabled={loading}
          className="my-5 w-[35%] mr-0 bg-darkGreen rounded-3xl text-white font-bold active:bg-formButtonHover hover:bg-formButtonHover shadow-shadow text-center py-0.5 px-6 h-12 transition-background-color duration-300 ease-linear "
        >
          {loading ? "Please Wait..." : "Submit"}
        </button>
      </div>
    </form>
  );
};

export default ApplyForm;
