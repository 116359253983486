import { HiOutlineArrowRight } from "react-icons/hi";
import { RiLinkedinFill, RiPhoneFill } from "react-icons/ri";
import { GrLocation } from "react-icons/gr";
import { MdOutlineMailOutline } from "react-icons/md";
import { ReactComponent as Logo } from "../../Assets/delatech-logo.svg";
import FooterData from "../Header/headerdata";
import { Link } from "react-router-dom";
import "./Footer.scss";

function Footer(props) {
  return (
    <footer className="bg-footerColor text-white relative">
      <div className="container">
        <span className="footer-logo block pt-5">
          <a href="/AboutUs" aria-label="Links of company">
            <Logo />
          </a>
        </span>

        <div className="footer cp-row pb-12">
          <div className="col-5">
            <p className="mt-5 pr-5 text-left">
              Where creativity meets quality in every project we undertake. At
              Delatech, we are a team of dedicated professionals who are driven
              by our shared passion for delivering outstanding results. Our
              agency thrives on the belief that when you love what you do, it
              shows in the work you produce. With each project we take on, we
              pour our hearts and souls into it, ensuring that every detail is
              meticulously crafted to perfection. We don't settle for
              mediocrity; instead, we strive for excellence in all aspects of
              our work.
            </p>
          </div>
          <div className="flex-[30%] col-4">
            <h2 className="hdl-3 text-white my-2.5">Links</h2>
            <ul className="flex-col md:flex-row pl-0 flex flex-wrap gap-5 items-start justify-start">
              {FooterData.map((links) => {
                return (
                  <li data-target={links.link} key={links.id}>
                    <Link
                      to={links.link}
                      aria-label="Information links"
                      className="no-underline text-white mb-2.5 hover:text-colorLight hover:cursor-pointer"
                    >
                      {links.title}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="col-3">
            <a
              href="/career"
              aria-label="Career at delatech"
              className="group footer-contactButton no-underline text-white flex w-full"
            >
              <div data-target="career" className="w-full text-left">
                <p className="copy-text group-hover:text-black">Career</p>
              </div>
              <span>
                <HiOutlineArrowRight />
              </span>
            </a>
            <button
              className="group footer-contactButton"
              onClick={props.onShowModal}
            >
              <p className="copy-text">Contact</p>
              <span>
                <HiOutlineArrowRight />
              </span>
            </button>
            <div className="flex justify-start gap-5 md:gap-2.5 flex-wrap">
              <div className="footer-contactIcon group">
                <span>
                  <a
                    rel="noreferrer"
                    href="https://al.linkedin.com/company/dela-tech"
                    target="_blank"
                    aria-label="Read more about company"
                    className="no-underline text-white"
                  >
                    <RiLinkedinFill />
                  </a>
                </span>
              </div>
              <div className="footer-contactIcon group footer-location">
                <span>
                  <a
                    rel="noreferrer"
                    href="https://www.google.com/maps/place/DELAtech/@41.336797,19.7894266,15.4z/data=!4m6!3m5!1s0x1350316df6e82801:0x1a930c5640f82f32!8m2!3d41.3366905!4d19.7968893!16s%2Fg%2F11txpt5n74?hl=sq"
                    target="_blank"
                    aria-label="Delatech location"
                    className="no-underline text-white"
                  >
                    <GrLocation />
                  </a>
                </span>
              </div>
              <div className="footer-contactIcon group">
                <span>
                  <a
                    rel="noreferrer"
                    href="mailto: info@dela-tech.com"
                    aria-label="contact us through email"
                    className="no-underline text-white"
                  >
                    <MdOutlineMailOutline />
                  </a>
                </span>
              </div>
              <div className="footer-contactIcon group">
                <span>
                  <a
                    href="tel: +355-69-283-5534"
                    aria-label="contact us throught phone number"
                    className="no-underline text-white"
                  >
                    <RiPhoneFill />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="pb-5">
          © Delatech {new Date().getFullYear()} All Rights Reserved.
        </div>
      </div>
    </footer>
  );
}

export default Footer;
